<template>
  <b-modal ref="modalid" class="modal-message" center hide-footer hide-header>
    <div class="d-block text-center">
      <h3>{{ message }}</h3>
    </div>
    <b-button class="mt-3" variant="outline-danger" block @click="hideModal"
      >Fermer</b-button
    >
  </b-modal>
</template>
<script>
export default {
  name: 'modal-message',
  props: {
    message: String
  },
  methods: {
    showModal() {
      this.$refs['modalid'].show()
    },
    hideModal() {
      this.$refs['modalid'].hide()
    }
  },
  computed: {
    modalId: function() {
      let result = this.$uuid.v5()
      console.log(result)
      return result
    }
  }
}
</script>
<style></style>
