var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modalid",
      staticClass: "modal-message",
      attrs: { center: "", "hide-footer": "", "hide-header": "" }
    },
    [
      _c("div", { staticClass: "d-block text-center" }, [
        _c("h3", [_vm._v(_vm._s(_vm.message))])
      ]),
      _c(
        "b-button",
        {
          staticClass: "mt-3",
          attrs: { variant: "outline-danger", block: "" },
          on: { click: _vm.hideModal }
        },
        [_vm._v("Fermer")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }