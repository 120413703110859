var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main-container", attrs: { id: "fileupload-tests" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form",
                    { ref: "step", attrs: { novalidate: "" } },
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "button" },
                            [
                              _c(
                                "file-upload",
                                {
                                  ref: "fuRegistrationCert",
                                  attrs: {
                                    "input-id": "fuRegistrationCert",
                                    "post-action": "/post.method",
                                    "put-action": "/put.method"
                                  },
                                  on: {
                                    "input-file": _vm.inputFileRegistrationCert,
                                    "input-filter":
                                      _vm.inputFilterRegistrationCert
                                  },
                                  model: {
                                    value: _vm.files,
                                    callback: function($$v) {
                                      _vm.files = $$v
                                    },
                                    expression: "files"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "caption" }, [
                                    _vm._v("AJOUTER UNE PHOTO")
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c("AlertMessage", {
                            ref: "alertFileUpload",
                            attrs: {
                              message:
                                "Seuls les fichiers images (PNG / JPEG) ainsi que PDF sont supportés"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }