<template>
  <div>
    <b-container id="fileupload-tests" class="main-container">
      <b-row>
        <b-col>
          <b-form ref="step" novalidate>
            <b-form-row>
              <b-col class="button">
                <file-upload
                  ref="fuRegistrationCert"
                  input-id="fuRegistrationCert"
                  v-model="files"
                  post-action="/post.method"
                  put-action="/put.method"
                  @input-file="inputFileRegistrationCert"
                  @input-filter="inputFilterRegistrationCert"
                >
                  <span class="caption">AJOUTER UNE PHOTO</span>
                </file-upload>
              </b-col>
              <AlertMessage
                ref="alertFileUpload"
                message="Seuls les fichiers images (PNG / JPEG) ainsi que PDF sont supportés"
              ></AlertMessage>
            </b-form-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import FileUpload from 'vue-upload-component'
import AlertMessage from '@/components/AlertMessage.vue'

export default {
  name: 'fileupload-tests',
  components: { FileUpload, AlertMessage },
  data() {
    return {
      files: [],
      fileToUpload: ''
    }
  },
  methods: {
    async customAction(file, component) {
      // return await component.uploadPut(file)
      return await component.uploadHtml4(file)
    },
    inputFileRegistrationCert: function(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response)
        if (newFile.xhr) {
          //  Get the response status code
          console.log('status', newFile.xhr.status)
        }
      }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilterRegistrationCert: function(newFile, oldFile, prevent) {
      console.log('file processing ...')
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp|pdf)$/i.test(newFile.name)) {
          this.$refs.alertFileUpload.showModal()

          return prevent()
        }
      }

      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
      console.log(newFile.blob)
      // Send to API
      let currentUser = this.$authService.getUser()
      let url =
        process.env.VUE_APP_API_BasePath +
        process.env.VUE_APP_API_Method_Vehicle_Base +
        currentUser.idToken.sub +
        '/registrationcert/content'

      let bearerToken = ''

      this.$authService
        .getAPIToken()
        .then(token => {
          bearerToken = token
        })
        .catch(err => {
          console.error('Error: ', err)
        })

      // TODO Save Registration Certificate
      // Upload base64 encoded content?

      this.fileToUpload = newFile.file
      let formData = new FormData()
      formData.append('file', this.fileToUpload)

      fetch(url, {
        // Your POST endpoint
        method: 'PUT',
        headers: new Headers({
          Authorization: 'Bearer ' + bearerToken
        }),
        body: formData // This is your file object
      })
        .then(response => {
          response.json()
        })
        .then(
          success =>
            console.log('New file uploaded to path: ' + success.filePath) // Handle the success response object
        )
        .catch(
          error => console.log(error) // Handle the error response object
        )
    },

    processFile(event) {
      console.log('FILE' + event)
    }
  }
}
</script>
<style lang="scss" scoped></style>
